/* decision tree functions*/
/* Calling the omniture at start of the JS 
 */
//Global function to read url parameters
import DpTreeVideo from './dp-tree-video';
const stagesOrder = ['T', 'N', 'M', 'Stage', 'Biomarkers']; // reference order to display different stages
const domain = getDomain();
var typeParam;
var subTypeParam;
var typeUrlPram = $('#typeUrlParam').val() ? $('#typeUrlParam').val() : "ctype";
var subtypeUrlPram = $('#subtypeUrlParam').val() ? $('#subtypeUrlParam').val() : "ttype";
var pagePathname = window.location.pathname;
var pageApiPathName = $('#dpName').val() ? $('#dpName').val() : "ODP";
var loadedContributorData;
var singleButtonDP = false;
var ifiInsertedInVideoResults = false;
var ifiVideoData;
const handleParametersAndRedirect = () => {
    typeParam = getUrlParameter(typeUrlPram);
    subTypeParam = getUrlParameter(subtypeUrlPram); //Redirect to the landing page if there is no parameter
    		
    if (typeParam === undefined || typeParam === "" || subTypeParam === undefined || subTypeParam === "") {	
        // location.replace('/dp');	 //location.replace('http://localhost:8080/contentmetadataservice/decisionpoint/landing');
		// Replacing location.replace with location.href this will register the URL in the history
        // Removed reroute when params are not there on tree page
        //location.href = url;
	}	
}

handleParametersAndRedirect();
setOmnitureForNSCLC();

// Event listener to get data from the IFI video
window.addEventListener('message', function(event) { 
    let message = typeof event.data == "string" && event.data.indexOf("loadIfiVideo") > 0 ? JSON.parse(event.data) :"";
    if(message.callback && message.callback == "loadIfiVideo") {
        ifiVideoData = message['payload'];
        addIfiVideoinVideoResults();
    }
});


function getUrlParameter(sParam) {	
    var sPageURL = decodeURIComponent(window.location.search.substring(1)), 
        			sURLVariables = sPageURL.split('&'),
                    sParameterName, i;
    for (i = 0; i < sURLVariables.length; i++) {				
        sParameterName = sURLVariables[i].split('=');        			
        if (sParameterName[0] === sParam) {					
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1].replace(/\+/g, ' '));				
        }			
    }
}

/* Below self envoking function is to add 
 * parameter in s_context  when SDLC is slected as tymor type
 */
function setOmnitureForNSCLC() {
    let s_context = window.s_context;
    let c_type = getUrlParameter(typeUrlPram);
    let t_type = getUrlParameter(subtypeUrlPram);
    // Add s_context in window object if not present
    if (typeof (s_context) == 'undefined') {
        Object.defineProperty(window, 's_context', {
            value: {},
            writable: true
        });
    }
    // Checking for Lung cancer and tumor type NSCLC
    if (t_type && c_type && c_type == "Lung Cancer" && t_type == "NSCLC") {
        window.s_context['wb.sptr'] = 'eli lilly & company [ 924 ]';
        window.s_context['wb.sact'] = '279584.1';
        window.s_context['wb.sactid'] = '279584.1';
        window.s_context['wb.sprodid'] = '5031';
    }
    // Checking for breast cancer and tumor type BC/Hormone-Positive
    if (t_type && c_type && c_type == "Breast Cancer" && t_type == "Hormone Receptor-Positive") {
        window.s_context['wb.sptr'] = 'pfizer, inc. [ 41 ]';
        window.s_context['wb.sact'] = '398394.1';
        window.s_context['wb.sactid'] = '398394.1';
        window.s_context['wb.sprodid'] = '5031';
    }
    // Checking for breast cancer and tumor type HER2-Positive
    if (t_type && c_type && c_type == "Breast Cancer" && t_type == "HER2-Positive") {
        window.s_context['wb.sptr'] = 'genentech, inc. [ 20050 ]';
        window.s_context['wb.sact'] = '280532.1';
        window.s_context['wb.sactid'] = '280532.1';
        window.s_context['wb.sprodid'] = '5031';
    }

    //PPE-300907 DP Affiliate Page Rebranding on trees
    //method to create referrer cookie, update the sf no, and change the branding image using data from ecd query param
    //get ecd query param
    let ecdString = getUrlParameter('ecd');
    if (typeof ecdString !== 'undefined' && ecdString !== null) {
        //check if ecd string param is for mdedge or mdalert
        if (ecdString.split('_')[2] === 'medp' || ecdString.split('_')[2] === 'madp') {
            //get referrerVal and set cookie
            let referrerVal = ecdString.split('_')[2];
            window.sessionStorage.setItem('referrerVal', referrerVal);
            //get sfNo and set cookie
            let sfNo = ecdString.split('_')[3];
            if (typeof sfNo !== 'undefined') {
                sfNo = sfNo.replace('-', '.');
            }
            window.sessionStorage.setItem('sfNo', sfNo);
            //update act id if it exists on the page
            if (typeof window.s_context['wb.sact'] !== 'undefined' && window.s_context['wb.sact'] !== '') {
                if (window.s_context['wb.sact'] === '398394.1') {
                    window.s_context['wb.sact'] = sfNo;
                    //replace image
                    $('.dp-title-block').addClass(referrerVal);
                }
            }
        }
    }
    //if there is no ecd query param and sfno and referrerval are set in session storage, update logo
    else {
        if (window.sessionStorage.getItem('sfNo') !== null && window.sessionStorage.getItem('referrerVal') !== null && typeof window.s_context['wb.sact'] !== 'undefined') {
            if (window.s_context['wb.sact'] === '398394.1') {
                window.s_context['wb.sact'] = window.sessionStorage.getItem('sfNo');
                //replace image
                $('.dp-title-block').addClass(window.sessionStorage.getItem('referrerVal'));
            }
        }
    }
    //make sure updated sact value is passed into ads call
    if (typeof window.s_context['wb.sact'] !== 'undefined') {
        if (window.s_context['wb.sact'] !== '') {
            webmd.ads2.setPageTarget('dseg', window.s_context['wb.sact']);
        }
        //pass in 0 for blank values
        else {
            webmd.ads2.setPageTarget('dseg', 0);
        }
    }
}

window.wmdTrackSafe = (modval, elem) => {
    try {		
        wmdTrack(modval, elem);	
    } catch (e) {		
        console.log(e);	
    }
}

//TODO make this generic for other staging system like TNM
const getStagingQueryPath = () => {
	let path = "";
	$( ".branch--title" ).each(function() {
        let branchTitle = $(this).find("span").attr( "data-value" );
        if( localStorage[branchTitle] && localStorage[branchTitle] !== 'undefined') {
            if (path) {
                path = path + ";" + localStorage[branchTitle]
            } else {
                path = localStorage[branchTitle]
            }
        }
    });
    /* If selections are not retrived try to get 
     * the user selections 
     * from the url
     * At the end the selections are retrived from localstorage only
     * Since user can unselect the slections from url also
     */
    if (!path) {
        let urlParams = parseUrlParamsForStagesPreselection(decodeURIComponent(window.location.href));
        if(urlParams && urlParams.length > 0) {
            urlParams.forEach(i => {
                const pair = i.split('=');
                if (path && localStorage.hasOwnProperty(pair[0]) && localStorage[pair[0]] != "undefined") {
                    path = path +";"+ localStorage[pair[0]];
                } else if(localStorage.hasOwnProperty(pair[0]) && localStorage[pair[0]] != "undefined"){
                    path = localStorage[pair[0]];
                }
            });
        }        
    }
	return path;
}

/*
 * searchPath Stage II, Stage II; BRAF, this is used in showing matching labels in the video card.
 * 
 * */

/*
 * Below function to add contributor section in the treepage
 * Which is the landing page of new layout
 */
var contributorData;
var contributorTitleArr=[];
function removePartnerBadgeSection() {
    if ($(".partner-badge").length) {
        $(".partner-badge").remove();
    }
}
function addPartnerBadge(contributorData, type) {
    var partnerBadgeUrl;
    if(contributorData){
    contributorData.contributerData.forEach(contributor => {
        if (contributor.title.toLowerCase() == type.toLowerCase()) {
            partnerBadgeUrl = contributor.partnerBadgeUrl;
        }
    })
    if (partnerBadgeUrl) {

        removePartnerBadgeSection();
        let partnerBadgeSection = "<div class='partner-badge'> <div class='partner-badge-text'><p>EDITORIAL COLLABORATION</p><img id = 'partnerBadge_img'></div></div>"
        $("div.video-results h2").after(partnerBadgeSection);
        $("#partnerBadge_img").attr("src", partnerBadgeUrl);
    }
    else {
        removePartnerBadgeSection();
    }
    }
}
function getContributorData() {
    let dynamicFileName = pageApiPathName.toLowerCase().concat(".json");
    let url = '//img.'+getDomain()+'medscapestatic.com/medscape-files/dp-contributors/'+dynamicFileName;
    var finalData;
    $.ajax({
        url: url,
        type: 'get',
        async: false,
        success: function (data) {
            finalData = data;
        }
    });
    return finalData;
}

const addContributors = () => {
    // Hiding the tree
    $(".navigate").hide();
    if(loadedContributorData){
	    let finalHeaderSection = "<section class='contributors' style='display:block'><h2>Expert Contributors</h2>";
	    
	    loadedContributorData.contributerData.forEach(jsonData=>{
	       let title = jsonData.title;
	       let className = title.replace(/\s+/g, '-').toLowerCase().concat("-contributors");
	       const contributorsSectionHeader = "<ul class="+className+"></ul>";
	       finalHeaderSection+=contributorsSectionHeader;
	     })
	     
	    finalHeaderSection+="</section>"
	    $("div.contributors").append( finalHeaderSection );
	    
        loadedContributorData.contributerData.forEach(jsonData => {
            let title = jsonData.title;
            let className = title.replace(/\s+/g, '-').toLowerCase().concat("-contributors");
            let contributors = $("div.contributors ul." + className);
            $(contributors).append("<div class='contributor-header'>" + title + "</div>");

            for (let _contributor of jsonData.data) {
                let expertTitle = _contributor["expertTitle"] ? `<div class="expert-title">${_contributor.expertTitle}</div>`: '';
                let contributorHtml = `<li><img src=${_contributor.profilePic} class="headshot" alt="${_contributor.expertName}"> <div class="expert">${_contributor.expertName}${expertTitle}</div></li>`

                $(contributors).append(contributorHtml);
            }

        })
    }
    // Showing the contributors
    $(".contributors").show();
}
const showAllLabels = (article, searchPath) => {
    let searchTags = searchPath.split(';');
    let allSelectedtags = [];
    for (let tagLabels of searchTags) {
        if(tagLabels && tagLabels.split(",").length > 0) {
            allSelectedtags = [...allSelectedtags, ...tagLabels.split(",")]
        } else {
            allSelectedtags = tagLabels.split(",")
        }
    }
    searchTags = allSelectedtags;
    let allLabelspaths = article.paths;
    
    
    let lablesMatingTags = '';
    allLabelspaths.map((elem)=>{
        let isSelected = false;
        searchTags.map((_elem)=>{
            if(_elem == elem){
                isSelected = true;
                lablesMatingTags = lablesMatingTags + '<li class="labels selected">'+ elem + '</li>';
            }
        })
        if(!isSelected){
            lablesMatingTags = lablesMatingTags + '<li class="labels">'+ elem + '</li>';    
        }
        isSelected = false;
    });
    return (lablesMatingTags);
}

// TODO need to make this more generic for other staging system
function renderVideos (articles, path) {	 // Clear info for mobile view
    if (breakpoint === 1 || breakpoint === 2) {		
        $('div.navigate div.results-amount-mobile span.video-number').text('');		
        $('div.navigate div.results-amount-mobile span.video-selection').text('');	
    }		 // Update and remove path, video numbers and all the video thumbnails
    
    $('div.results-amount span.video-number').text('');	
    $('div.results-amount span.video-selection').text('');	
    $('div.dp-card').remove();		
    const numOfVideo = articles === undefined ? 0 : articles.length;
    let selectionPath;	

    selectionPath = getStagingQueryPath().split(";").join(",");

    // This part is for mobile strip
    $('div.navigate div.results-amount-mobile span.video-number').text(numOfVideo + ' ');		 // This part is for desktop
    
    if (selectionPath) {		
        $('div.navigate div.results-amount-mobile span#path-prep').attr('style', 'display:inline');		
        $('div.navigate div.results-amount-mobile span.video-selection').text(selectionPath);	
    }else {
        $('div.navigate div.results-amount-mobile span#path-prep').attr('style', 'display:none');
    }

   	$('div.results-amount span.video-number').text(numOfVideo);

   	// This part shows for desktop and mobile
   	if (selectionPath) {
   		$('div.results-amount span#path-prep').attr('style', 'display:inline');
   		$('div.results-amount span.video-selection').text(selectionPath);
    } else {
        $('div.results-amount span#path-prep').attr('style', 'display:none');
    }	

   	if (articles === undefined) {
        return;
   	}

   	const type = $("input[type=hidden][name='cancer']").val();
   	const subType = $("input[type=hidden][name='tumor']").val();
    if (typeof ifiVideoData != "undefined") {
        articles.splice(1,0,ifiVideoData);
    }
    let videoHtml = "";
    articles.forEach(function (item) {
        if (typeof item["video"] != "undefined") {
            videoHtml += `<div class="dp-card dp-card-ifi-video">
                            <div class="dp-card-ifi-video-text">INFORMATION FROM INDUSTRY</div>
                            <div class="video-teaser">
                                <span class="video-thumbnail"><a><img src="${item["playlist"]["thumbnail"] || ''}" /></a></span>
                            </div>
                            <div class="title"><a>${item["title"] || ''}</a></div>
                            <div class="byline"><p class="meta-author">${item["playlist"]["jobCode"] || ''}</p></div>
                        </div>`;
            ifiInsertedInVideoResults = true;
        } else {
            const name = (item.firstName === null ? "" : item.firstName) +
                " " + (item.middleName === null ? "" : item.middleName) +
                " " + (item.lastName === null ? "" : item.lastName);
            const thumbnailUrl = item.url;
            const title = item.articleTitle;
            const duration = item.mediaDuration === null ? "" : item.mediaDuration;
            // Url Param for slections made
            let urlParam = "";
            $(".branch--title").each(function () {
                let branchTitle = $(this).find("span").attr("data-value");
                if (urlParam) {
                    urlParam = urlParam + "&" + branchTitle + "=" + (localStorage[branchTitle] ? localStorage[branchTitle] : "undefined");
                } else {
                    urlParam = branchTitle + "=" + (localStorage[branchTitle] ? localStorage[branchTitle] : "undefined");
                }
            });
            // TODO not to code stage or biomarkers in the url as parameters, use a dynamic way to do this otherwise it is not good for TNM system

            let articleUrlparams = encodeURIComponent(`${typeUrlPram}=${type}&${subtypeUrlPram}=${subType}&${urlParam}`);
            let articleUrl = `${location.origin}${pagePathname}/viewarticle/${item.articleId}?${articleUrlparams}`;

            const guidelineUrl = articleUrl + '#guidelines';
            const transcriptUrl = articleUrl + '#transcript';
            const subject = item.subject === null ? "" : item.subject;
            let matchingLabel = '';

            matchingLabel = showAllLabels(item, path);

            let newHtmlElement = "";
            let paddingClass = "";
            if (item.status != undefined && item.status.trim() != "") {
                newHtmlElement = '<span class="new-flag">' + item.status + '</span>';
                paddingClass = ' flag-padding';
            }

            videoHtml += `<div class="dp-card ${paddingClass}">
                            <div class="video-teaser">
                                <span class="video-thumbnail"><a href="${transcriptUrl}" onclick="wmdTrackSafe(\'dp-tree_img\', this);"><img src="${thumbnailUrl}" /></a></span>
                                <span class="duration">${duration}</span>
                            </div>${newHtmlElement}
                            <div class="title"><a href="${articleUrl}" onclick="wmdTrackSafe(\'dp-tree_title\', this);">${title}</a></div>
                            <div class="byline"><p class="meta-author">${name}<span class="meta-institution lastVisible">${subject}</span></p></div>
                            <div class="morelink"><a href="${guidelineUrl}" onclick="wmdTrackSafe(\'dp-tree_guide\', this);">View Guidelines</a></div>
                            <div class="matchingLabel">${matchingLabel}</div>
                        </div>`;
        }

    }); //end of foreach

    $('.video-results section.videos').append(videoHtml);
    addIfiEventListener();

    // Add animation on video results to make the video results visible on the UI
    let sectionVideos = $('section.videos');
	$(sectionVideos).removeClass('zero-opacity-animation');	  
	$(sectionVideos).addClass('animate-video-results');	   
}

const generateVideosContent = (type, subtype, path) => {
    let articleUrl;

    articleUrl = `//api.${domain}medscape.com/contentmetadataservice/decisionpoint/v1/${pageApiPathName}/type/${type}/subtype/${subtype}/articles`;
    if(path) articleUrl = `${articleUrl}?path=${path}`;
	 
	let articlesByTypeAndSubtype;
	// Add animation on video results to make the video results visible on the UI
	let sectionVideos = $('section.videos');
    $(sectionVideos).removeClass('animate-video-results');
    $(sectionVideos).addClass('zero-opacity-animation');
    if(type && subtype) {
        $.ajax({ 
            url: articleUrl,
            success: function(result){
                articlesByTypeAndSubtype = result;
            }
        }).done(function() {	
            renderVideos(articlesByTypeAndSubtype.articles, path);
        }).fail(function() {});
    } else {
        let noVideosYetText = "Select Type and Subtype above to view results.";
        noVideosYetText = singleButtonDP ? "Press start to find the right treatment for your patient." : noVideosYetText;
        const noVideosYet = `<div class="no-videos-yet">${noVideosYetText}</div>`;
        $('div.results-amount').hide();
        $("section.videos div.results-amount").after(noVideosYet);
        $(sectionVideos).addClass('animate-video-results');
    }
}

const getTypes = () => {
    let types;
    $.ajax({	 //url: "http://localhost:8080/contentmetadataservice/decisionpoint/ODP/types",
        //url: '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/ODP/types',
        // New endpoint
        url: '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/'+ pageApiPathName +'/types',
        	success: function(result) {	
            types = result;	
        }	
    }).done(function() {
        if ($('ul#cancerList.dropdown-list').children().length > 0) {			
            $('ul#cancerList.dropdown-list li').remove();		
        }
        if (types.types.length == 1) {
            checkForSingleSubtype(types.types[0]);
        } else {
            let typeSelectors = $(".type-selectors");
            if (breakpoint == 1) {
                $(typeSelectors).css('display', 'block');
            } else {
                $(typeSelectors).css('display', 'flex');
            }
            if (!typeParam && !subTypeParam) {
                increaseHeaderBackgroundHeight();
            } else if (typeParam && subTypeParam) {
                increaseHeaderBgHeightWithoutTreeHeading();
            }
        }
        jQuery.each(types.types, function(index, item) {	
            let ddList = $('.type-selectors ul#cancerList.dropdown-list');
            if (item === typeParam) {	
                $("input[type=hidden][name='cancer']").val(item);			
                $(ddList).append("<li data-value='" + item + "' class='enabled'>" + item + "</li>");	
            } else {		
                $(ddList).append("<li data-value='" + item + "'>" + item + "</li>");	
            }	
        });	 // Preset the value of cancer
        $('div#cancerList').find('span').text(typeParam ? typeParam : "Select");
        if(!typeParam && !subTypeParam) {
            addContributors();
        }	
    });
}

const getSubtypes = () => {	
    //const subTypeAPIUrl = '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/ODP/type/' + typeParam;
    const subTypeAPIUrl = '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/'+ pageApiPathName +'/type/' + typeParam;
    let subtypes;
    	
    $.ajax({	
        url: subTypeAPIUrl,
        	success: function(result) {	
            subtypes = result;	
        }	
    }).done(function() {	
        /* When a new menu item is triggered, all the previous subtype items will be erased
         * Otherwise they will add up and dup
         */
        		
        if ($('ul#tumorList.dropdown-list').children().length > 0) {				
            $('ul#tumorList.dropdown-list li').remove();			
        }			
        jQuery.each(subtypes.subtypes, function(index, item) {	
            let ddList = $('.type-selectors ul#tumorList.dropdown-list');
            if (item === subTypeParam) {	
                $("input[type=hidden][name='tumor']").val(item);		
                $(ddList).append("<li data-value='" + item + "' class='enabled'>" + item + "</li>");	
            } else {		
                $(ddList).append("<li data-value='" + item + "'>" + item + "</li>");	
            }	
        });		 //Preset value of tumor
        			
        $('div#tumorList').find('span').text(subTypeParam ? subTypeParam : "Select");						
        /* Function to hide the apply button if we already have cancer type
         * And tumor type
         */
        hideApplyButton($('div#tumorList').find('input').val(), $('div#cancerList').find('input').val());	
    });
}

// Preselection info will be retained in the url in the tree page url
const parseUrlParamsForStagesPreselection = (url) => {
    url = url.replace(/\+/g, ' ').split('?');
    url = url[1] ? url[1].split('&') : "";
    const sourcemarkers = "ecd/icd/src/uac";
    if (url) {
        for (let j = 0; j < url.length; j++) {
            let sParameterName = url[j].split('=');
            if (sourcemarkers.indexOf(sParameterName[0]) > -1) {
                url.splice(j, 1);
            }
        }
    }
    if (url[1]) {
        return url.splice(2);
    } else {
        return ""
    }
    
}	

// The params are used for preselection in tree page from article page
const renderStages = (stages, stageOrder, params, clearAll = true) => {	
    /*
     * The stages should be sorted based on T,N,M, biomarker sequence
     * There is a sorting logic here, if we can extract that tree relationship from db, please get rid of this.
     * Double check with more real and accurate data then change the following logic will be safer.
     *
     * The stage number, stage pod are dynamically generated.
     *
     * */
    		
    const keys = sortStages(stages, stagesOrder);		 // Remove the stages if any from previous selection
    $('.tree section.branch').remove();
    $('.tree section.select-all-that-apply').remove();
    $('.tree').append("<section class='select-all-that-apply'>Choose options below to filter your results. Select all that apply.</section>");
    let count = 1; // Counter label in UI
    // Getting params from Url for preselection only when clear All button is not clicked
    if(clearAll){
        params = parseUrlParamsForStagesPreselection(decodeURIComponent(window.location.href));
    }
    		
    if (params) {
        // Use localStorage as the hashmap to hash key/value into it for easy lookup
        params.forEach(i => {
            const pair = i.split('=');
            localStorage.setItem(pair[0], pair[1].trim());
        });
    }

    for (let key in Object.values(keys)) {
        const stageName = Object.values(keys)[key];	
        if (localStorage.getItem(stageName) === 'undefined') {
        	localStorage.setItem(stageName,'');
        }
    }
    
    for (let key in Object.values(keys)) {
        const stageName = Object.values(keys)[key];	
        const stageValues = stages[Object.values(keys)[key]];

        // Preselection from redirection from article page
        // Handle multiple values separated by comma
        	
        let preselectedStageValues = localStorage.getItem(stageName);	
        if (preselectedStageValues) {
            preselectedStageValues = preselectedStageValues.split(',');	
        }

        if (params && params.length > 0) {
            $('.tree').append("<section class='branch'>");
            let treeBranch = $('.tree .branch').last();
            $(treeBranch).append("<div class='branch--number'><span>" + count++ +"</span></div>");		
            $(treeBranch).append(`<div class='branch--title'><span data-value="${stageName}">${stageName}</span></div>`);
            $(treeBranch).append("<ul>");
            let item;

            for (let i = 0; i < stageValues.length; i++) {
                item = stageValues[i];
                let found = false;
                if (localStorage.hasOwnProperty(stageName)) {
                    if (Array.isArray(preselectedStageValues)) {
                        preselectedStageValues.forEach(i => {
                            i = i.trim();
                            if (i == item) {
                                $(treeBranch).find('ul').append("<li onclick='' data-value='" + item + "'" + 'class="selected">' + item + "</li>");			
                                found = true;
                            }
                        });
                    }
                    
                    if (!found) {
                        // If not highlight the item then just display it
                        $(treeBranch).find('ul').append("<li onclick='' data-value='" + item + "'>" + item + "</li>");
                        found=false;
                    }
                } 
            }	
          
        } else {
        	// The following is the initial state to render all the stages
            // First node is the active node, all others in sequence are unselected
            if (count === 1) {			
                $('.tree').append("<section class='branch'>");		
                let treeBranch = $('.tree .branch');	
                $(treeBranch).append("<div class='branch--number'><span>" + count++ +"</span></div>");			
                $(treeBranch).append(`<div class='branch--title'><span data-value="${stageName}">${stageName}</span></div>`);			
                $(treeBranch).append("<ul>");			
                let item;
                for (let i = 0; i < stageValues.length; i++) {				
                    item = stageValues[i];				 // When there is no redirect parameters then it will show default staging options
                    $('.tree .branch ul').append("<li onclick='' data-value='" + item + "'>" + item + "</li>");			
                }		
            } else {			
                $('.tree').append("<section class='branch'>");			
                const lastUnselectedNode = $('section.branch').last();			
                lastUnselectedNode.append("<div class='branch--number'><span>" + count++ +"</span></div>");			 // If the span label needs to display a long string like 'tumor extent', we will see if we need a look up or coming from db
                lastUnselectedNode.append(`<div class='branch--title'><span data-value="${stageName}">${stageName}</span></div>`);						 // Add explanatory text for multiselection of biomarkers
                lastUnselectedNode.append("<ul>");			
                stageValues.forEach(function(item) {			
                    lastUnselectedNode.children('ul').append("<li onclick='' data-value='" + item + "'>" + item + "</li>");			
                });		
            }
            
            // Clear the localStorage whenever the new stages was retrieved, which means the type/subtype was reset
            localStorage.clear();
        }
    }	 
    var divHeight = $('div.navigate').height();
    $('div.video-results').css('min-height', divHeight + 'px');
    // Call the following to see if the startover button should show or not        
}


const getStages = (type, subtype) => {
    const stagesAPIUrl = '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/'+ pageApiPathName +'/type/' + type + '/subtype/' + subtype;		 // Generate stages, either T, biomarker or StageN
    return $.ajax({
        //url: "http://localhost:8080/contentmetadataservice/decisionpoint/ODP/type/"+ typeParam + '/subtype/' + subTypeParam,
        url: stagesAPIUrl,
        success: function(result) {
        }
    });
}

const getArticleTags = (type, subtype) => {
    if (type && subtype) {
        const articalTagsAPIUrl = '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/'+ pageApiPathName +'/type/' + type + '/subtype/' + subtype;		 // Generate stages, either T, biomarker or StageN
        return $.ajax({
            // http://contentmetadataservice-app-qa01.prf.ma1.medscape.com:8080/contentmetadataservice/decisionpoint/ODP/type/Lung%20Cancer/subtype/Mesothelioma/articlepaths
            url: articalTagsAPIUrl,
            success: function(result) {
            }
        });
    } else {
        return Promise.resolve();
    }
}

/* Function sortStages(param) will returned the sorted keys/stages name
 * Right now, in database, the T, Biomarker or Stage[1,2,3] are on the top
 * level, same level based on a communication with CMS.
 *
 * If T->N, N->M, M->Biomarker releation in database is durable,
 * we do not need to code the above order, we will read from database
 * by given cancer and tumor name and then we get a few records,
 * and we join those record, and we can get a path from backend api
 * for the order like T->N->M->Biomarker.
 *
 * It depends on how the data is stored, in any case.
 *
 */

const sortStages = (stagesParam, stagesOrder) => {	
    /* Sorting is being done by the backend
     * So returning keys only 
     * from this function
     */	
    return stagesParam ? Object.keys(stagesParam) : {};
}

/* Function to hide the apply button and 
 * Also to hide the right border
 */
const hideApplyButton = (cancerType, tumorType) => {	
        if (cancerType && tumorType) {		
            $('.type-selectors .start-btn').hide();		
            $('div#tumorList.dropdown').addClass('dropdown-no-border');	
        }
    }



const renderStage = (typeParam, subTypeParam, data) => {
        
    	let newData = {};
 	    	getArticleTags(typeParam, subTypeParam).then(function(articlesTags){
        		//const tags = articlesTags.articlepaths.join();
        		newData = articlesTags;
               
                return new Promise( function(resolve, reject) {
                    resolve(newData);
                });
 	    	}).then(function() {   
        	    renderStages(newData, stagesOrder);
        	});
}

const initPageSetup = () => {
     /* Below line of code to clear the local storage
     * when we navigate to the decision-tree page
     */
    localStorage.clear();
    getTypes();	
    getSubtypes();
    loadedContributorData = getContributorData();
    $('section.branch').remove();
    const params = parseUrlParamsForStagesPreselection(decodeURIComponent(window.location.href));
    if (Array.isArray(params) && params.length > 0 ) {
        // Use localStorage as the hashmap to hash key/value into it for easy lookup
        params.forEach(i => {
            const pair = i.split('=');
            if(pair.length > 1) {
                localStorage.setItem(pair[0], pair[1]);
            }            
        });
         /* Hide the tree page header and decrease the background
         * when cancer and tumor type are already present in URL
         */
        if (window.location.href.indexOf(subtypeUrlPram) > -1){
            hideTheTreePageHeadings();
            $('div#cancerList span').addClass("selected");
            $('div#tumorList span').addClass("selected");
        }
        /* getStages will rerender the stages in the main content also vidoes in the sidebar*/
        getStages(typeParam, subTypeParam).then(function(stageData) {
        	renderStage(typeParam, subTypeParam, stageData);
        	addPartnerBadge(loadedContributorData,typeParam);
        	// For stage preselection from article page
            generateVideosContent(typeParam, subTypeParam, getStagingQueryPath());
            
            // Function to hide the apply button if we already have cancer type and tumor type
            hideApplyButton($('div#tumorList').find('input').val(), $('div#cancerList').find('input').val());
            
        }); 
        
    } else {
        if(!subTypeParam){
            typeParam = $('div#cancerList').find('input').val();
            subTypeParam = $('div#tumorList').find('input').val();
        }
        if(subTypeParam) {
            hideTheTreePageHeadings();
            $('div#cancerList span').addClass("selected");
            $('div#tumorList span').addClass("selected");
        }
 	    getStages(typeParam, subTypeParam).then( function(stageData) {
              renderStage(typeParam, subTypeParam, stageData);
              addPartnerBadge(loadedContributorData,typeParam);
 	    	generateVideosContent(typeParam, subTypeParam, getStagingQueryPath());
	    	// Added below code to hide the Apply button on page load
	    	hideApplyButton(true, true);
	    });
    }
}
 
/* Function to hide and show the tree page headers 
 * when biomarker and cancer type has been selected
 */
const hideTheTreePageHeadings = () => {
    //$(".tree-header").hide();
    $(".tree-header .clinical-guidelines").hide();
    $(".tree-header .select-cancer-type-a").hide();
    
    if(breakpoint == 1) {
        $(".tree-header").css('margin-top',"132px");
    } else {
        $(".tree-header").css('margin-top',"112px");
    }
}

/**
 * Function to increase the background height if dropdown of cancer 
 * and tumor type dropdown is available
 */
const increaseHeaderBackgroundHeight = () => {
    // For Desktops
    $("#bodypadding").addClass("increased-bg");
    // For mobile devices
    $("body.decision-tree").css("background-size", "auto " + "345px");
}

/**
 * Function to increase the background height if tree does
 * have the start button
 */
const increaseHeaderBgHeightWithStartButton = () => {
    // For Desktops
    $("#bodypadding").addClass("increased-bg");
    // For mobile devices
    $("body.decision-tree").css("background-size", "auto " + "300px");
}

/**
 * Function to increase the background height if tree does not  
 * have the heading
 */
const increaseHeaderBgHeightWithoutTreeHeading = () => {
    // For Desktops
    $("#bodypadding").addClass("increased-bg-without-tree-heading");
    // For mobile devices
    $("body.decision-tree").css("background-size", "auto " + "255px");
    if(breakpoint == 1) {
        $(".tree-header").css('margin-top',"0px");
    } else {
        $(".tree-header").css('margin-top',"37px");
    }
}

// Check for one subtype if only one tyep is present
const checkForSingleSubtype = (singleTypeParam) => {
    const subTypeAPIUrl = '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/' + pageApiPathName + '/type/' + singleTypeParam;
    let subtypes;

    $.ajax({
        url: subTypeAPIUrl,
        success: function (result) {
            subtypes = result;
        }
    }).done(function () {
        if (subtypes.subtypes.length == 1 && !subTypeParam) {
            $(".select-cancer-type-a").text("Press start to find the right treatment for your patient.");
            injectSingleButton();
            increaseHeaderBgHeightWithStartButton();
            $(".dropdowns").css('width', '100%');
            singleButtonDP = true;
            if ($('.no-videos-yet').length > 0) {
                $('.no-videos-yet').text("Press start to find the right treatment for your patient.")
            }
        } else if(subTypeParam && typeParam) {
            increaseHeaderBgHeightWithoutTreeHeading();
            if (subtypes.subtypes.length == 1) {
                $("#cancerList").removeClass("dropdown");
                $("#tumorList").removeClass("dropdown");
            }
            if (breakpoint == 1) {
                $(".type-selectors").css('display', 'block');
            } else {
                $(".type-selectors").css('display', 'flex');
            }
        } else {
            increaseHeaderBackgroundHeight();
            if (breakpoint == 1) {
                $(".type-selectors").css('display', 'block');
            } else {
                $(".type-selectors").css('display', 'flex');
            }
        }
    });
}
// Add single button in DOM 
const injectSingleButton = () => {
    const singleBtnHtml = `<section class="single-select"><div class="single-select-button">Start</div></section>`;
    $(".type-selectors").hide();
    $(".dropdowns").append(singleBtnHtml);
    $("#cancerList li").click();
    enableSingleButtonLayout();
}
// Display single button 
const enableSingleButtonLayout = () => {
    $(document).on('click', '.single-select-button', function () {
        $("#tumorList li").click();
    });
}

// Function add a modal pop up on the tree page
function addModalOverlayForVideo() {
    // code for adding a modal over lay
    const overlayModalHtml = `<div style="display:none" class="dp-tree-modal-overlay" id="whiteoutlayer"></div>
                                <div style="display:none" class="modal-layer dp-tree-ifi-modal">
                                <span class="dp-tree-ifi-modal-title">Information From Industry</span><span class="close"></span>
                                <div id="dp-video-player"></div>
                                <div class="dp-tree-ifi-modal-html">${ifiVideoData["html-content"] || ''}</div>
                                <div class="dp-tree-ifi-modal-ifi">Information From Industry</div>
                                <div class="dp-tree-ifi-modal-text">${ifiVideoData["title"] || ''}</div>
                                <div class="dp-tree-ifi-modal-jobcode">${ifiVideoData["playlist"]["jobCode"] || ''}</div>
                                </div>`;
    $( "#headerbox" ).after(overlayModalHtml);
}

// Function to add event listener for the ifi video 
function addIfiEventListener() {
    if(!ifiVideoData){
        return;
    } 
    $("section.videos .dp-card-ifi-video").on("click", function () {
        if (typeof window.myVideoPlayer != undefined) {
            window.myVideoPlayer.setMedia(ifiVideoData["video"]);
        }
        $(".dp-tree-modal-overlay").show();
        $(".dp-tree-ifi-modal").show();
        $("body").addClass("modal-layer-on");
    });
    $(".dp-tree-ifi-modal .close").on("click", function () {
        $(".dp-tree-modal-overlay").hide();
        $(".dp-tree-ifi-modal").hide();
        window.myVideoPlayer._amp.pause();
        $("body").removeClass("modal-layer-on");
    });
}

// Function to add IFI video in the video results 
function addIfiVideoinVideoResults() {
    if (!ifiInsertedInVideoResults) {
        $("section.videos").children(':eq(1)').after(`<div class="dp-card dp-card-ifi-video">
                                                            <div class="dp-card-ifi-video-text">INFORMATION FROM INDUSTRY</div>
                                                            <div class="video-teaser">
                                                                <span class="video-thumbnail"><a><img src="${ifiVideoData["playlist"]["thumbnail"] || ifiVideoData["video"]["poster"] || ''}"/></a></span>
                                                            </div>
                                                            <div class="title"><a>${ifiVideoData["title"] || ''}</a></div>
                                                            <div class="byline"><p class="meta-author">${ifiVideoData["playlist"]["jobCode"] || ''}</p></div>
                                                        </div>`);
    }
    addModalOverlayForVideo();
    let dpIfiTreeVideoPlayer = new DpTreeVideo(ifiVideoData);
    dpIfiTreeVideoPlayer.init();
    addIfiEventListener();
}
$(document).ready(function() {  
    initPageSetup();
    
    $(document).on('click', 'button.start-btn', function () {
        const selectedType = $("input[type=hidden][name='cancer']").val();
        const selectedSubtype = $("input[type=hidden][name='tumor']").val();

        if (!selectedType || !selectedSubtype) {
            return;
        }		
        let url;		
        /* Clear the local storage so that stage and biomarkers are not already selected
         * Added by Ram to fix the issue PPE-194575
         */

        localStorage.clear();	
        //url = "/dp/decision?ctype=" + selectedType + "&ttype=" + selectedSubtype;
        url = `/dp/decision?${typeUrlPram}=${selectedType}&${typeUrlPram}=${selectedSubtype}`
        wmdTrackSafe('dp-tree_apply', this);	
		//location.replace(url);
		// Replacing location.replace with location.href this will register the URL in the history
		location.href = url;
    });

    // When click on the first level visible branch, then enable the next level
    $(document).on('click', '.tree section ul li', function enableNextStage() {	
        /* Grey out current title when you click any staging
         * https://app.zeplin.io/project/5c743598103c2e1b7f00ce86/screen/5c74366f30283132e8755156
         */
        const currentSectionNode = $(this).closest('section');
        currentSectionNode.children('div.branch--title').addClass('unselected');

        // Set properties for next unselected node
        const nextUnselectedNode = currentSectionNode.next('.unselected');
        nextUnselectedNode.removeClass('unselected');
        nextUnselectedNode.children('.branch--title').removeClass('unselected');

        const stageLabel = nextUnselectedNode.children('.branch--title').children('span').attr('id');
        nextUnselectedNode.children('.branch--title').children('span').text(stageLabel);
        // Add animation in the tree branch
        nextUnselectedNode.addClass('animation');
        /* Enable video count mobile strip when click the first stage
           The following div are already in jsp but they dont have default values.
           They should be invisible by default.
        */
        if (breakpoint === 1) {
            $('div.navigate div.results-amount-mobile').show();	
        }
        
        // Only allow one item being selected, if found other sibling
        // was selected then deselect it! But allow Biomarker to have multiple selections
        const currentBranchTitle = currentSectionNode.children('div.branch--title').find("span").attr( "data-value" );
        // below if is to create the property in the localstorage
        if (!localStorage[currentBranchTitle] || localStorage[currentBranchTitle] === 'undefined' || localStorage[currentBranchTitle] === '') {
            localStorage.setItem(currentBranchTitle, $(this).attr( "data-value" ));
            $(this).addClass('selected');
        } 
        // below if is to remove the already selected values
        else if ($(this).hasClass('selected')) {
            // Remove current selection
            let alreadySelected = localStorage[currentBranchTitle].split(",");
            $(this).toggleClass('selected');
            for (var i = 0; i < alreadySelected.length; i++) {				
                if (alreadySelected[i] === $(this).attr( "data-value" )) {					
                    alreadySelected.splice(i, 1);				
                }			
            }
            localStorage.setItem(currentBranchTitle, alreadySelected.join(","));
        } else {
            let alreadySelected = localStorage[currentBranchTitle].split(",");
            alreadySelected.push($(this).attr( "data-value" ));
            $(this).addClass('selected');
            localStorage.setItem(currentBranchTitle, alreadySelected.join(","));
        }
        

        /* Changed the If condition to enable multiple stage salection and
         * Removed the functionality which removes the boimarker selection when 
         * new stage is selectedSubtype
         */

        
        
        const selectedType = $("input[type=hidden][name='cancer']").val();
        const selectedSubtype = $("input[type=hidden][name='tumor']").val();
        addPartnerBadge(loadedContributorData,selectedType);	
        generateVideosContent(selectedType, selectedSubtype, getStagingQueryPath());
        
    });
    

    $(document).on('click', '.dropdown', function () {
        $(this).attr('tabindex', 1).focus();
        $(this).toggleClass('active');
        $(this).find('.dropdown-list').slideToggle(300);
    });

    $(document).on('focusout', '.dropdown', function () {
        $(this).removeClass('active');
        $(this).find('.dropdown-list').slideUp(300);
    });	

    $(document).on('click', '.dropdown ul#cancerList.dropdown-list li', function () {
        $('.enabled').removeClass('enabled');
        $(this).addClass('enabled');
        let dropdownParent = $(this).parents('.dropdown');
        $(dropdownParent).find('class').removeClass('active');
        $(dropdownParent).addClass('selected');
        $(dropdownParent).find('span').text($(this).text());
        $(dropdownParent).find('span').addClass('selected');
        $(dropdownParent).find('input').attr('value', $(this).attr( "data-value" ));
        // Hide apply button as tumor type is not selected

        hideApplyButton(true, true);
        //Get subtypes
        let subtypes;
        $.ajax({			
            url: '//api.' + domain + 'medscape.com/' + 'contentmetadataservice/decisionpoint/v1/'+ pageApiPathName +'/type/' + $(this).attr( "data-value" ),
            			success: function(result) {				
                subtypes = result;		
            }	
        }).done(function() {		
            /* When a new menu item is triggered, all the previous subtype items will be erased
             * Otherwise they will add up and dup
             */

            if ($('ul#tumorList.dropdown-list').children().length > 0) {			
                $('ul#tumorList.dropdown-list li').remove();		
            }					
            jQuery.each(subtypes.subtypes, (index, item) => {			
                $('ul#tumorList.dropdown-list').append("<li data-value='" + item + "'>" + item + "</li>");			
            });	
        });				 //Init the following values
        let tumorList = $('div#tumorList');
        $(tumorList).find('span').text("Select");		
        $(tumorList).find('input').attr('value', '');
        $(tumorList).find('span').removeClass("selected");
        let selectedValue = $(this).attr("data-value").toLowerCase();
        if(loadedContributorData){
        loadedContributorData.contributerData.forEach(jsonData=>{
            let title = jsonData.title;
            contributorTitleArr.push(title.toLowerCase());
          })
        }
        if(loadedContributorData && contributorTitleArr.includes(selectedValue)){
            addPartnerBadge(loadedContributorData,selectedValue);
            loadedContributorData.contributerData.forEach(conttributor=>{
                
            let title = conttributor.title;
            let className = title.replace(/\s+/g, '-').toLowerCase().concat("-contributors");
            let contributorsEle = $("div.contributors ul."+className);
           if (selectedValue === conttributor.title.toLowerCase()) {
               $(contributorsEle).fadeIn("slow", function () {});
           }
           else{
              $(contributorsEle).fadeOut("slow", function () {});
           }
        
            })
        }
        else{
            removePartnerBadgeSection();
        }
    });

    $(document).on('click', '.dropdown ul#tumorList.dropdown-list li', function () {
        $('.enabled').removeClass('enabled');
        $(this).addClass('enabled');
        let dropDownParents = $(this).parents('.dropdown');
        $(dropDownParents).find('class').removeClass('active');
        $(dropDownParents).addClass('selected');
        $(dropDownParents).find('span').text($(this).text());
        $(dropDownParents).find('span').addClass('selected');		
        $(dropDownParents).find('input').attr('value', $(this).attr( "data-value" ));		 // Show apply button when
        		
        //marker
        hideApplyButton();
        
        var created_selectedType = $("input[type=hidden][name='cancer']").val();
        var created_selectedSubtype = $("input[type=hidden][name='tumor']").val();
        let currentURL = location.href.split("/");
        let reloadParam = currentURL[currentURL.length - 1];
        try { wmdTrack('dp-land_start', this); } catch(e) { console.log(e); }
		location.href = `${window.location.origin}${pagePathname}?${typeUrlPram}=${created_selectedType}&${subtypeUrlPram}=${created_selectedSubtype}`;
    });

    // Startover button
    $(document).on('click', 'button.clear-all', () => {
        const selectedType = $("input[type=hidden][name='cancer']").val();
        const selectedSubtype = $("input[type=hidden][name='tumor']").val();

        // Hide the 'for' word in the label
        /*mobile*/
        $('div.navigate div.results-amount-mobile span#path-prep').attr('style', 'display:none');

        /* Desktop*/
        $('div.video-results div.results-amount span#path-prep').attr('style', 'display:none')

        // Reset selection path
        localStorage.clear();

        getStages(selectedType, selectedSubtype).then( function(data) {

            renderStages(data, stagesOrder,'',false);
            addPartnerBadge(loadedContributorData,typeParam);
	    	generateVideosContent(typeParam, subTypeParam, getStagingQueryPath());
	    	    
	    	// Added below code to hide the Apply button on page load
	    	hideApplyButton(true, true);
	    });
       
    });

    // Close 'video results' mobile strip
    $(document).on('click', 'div.results-amount-mobile > div.close-modal', (_event) => {		
        $('div.results-amount-mobile').hide();			 // Added by ram to stop the scroll when clicked on 'x' of the strip
        _event.stopPropagation();	
    });

    // Go to the video results on taping the mobile strip
    $(document).on('click', 'div.results-amount-mobile', () => {	
        $('html, body').animate({		
            scrollTop: $('.video-results').offset().top	
        }, 1000);
    });

}); //end of ready()

