export default class DpTreeVideo {

    constructor(config) {
        this.ifiConfig = config;
        this.ifiDefaultConfig = {
            "title": "",
            "videoId": "",
            "ccFileRoot": "",
            "source": "",
            "poster": "",
            "autoplay": true,
            "enableMute": true,
            "muted":true,
            "ccInfo": []
        }
    }

    init() {

        let {
            videoId,
            poster,
            source,
            duration
        } = this.ifiConfig['video'];

        // Object Destructuring not working so assigning the values explicitly 
        this.ifiDefaultConfig["videoId"] = videoId;
        this.ifiDefaultConfig["poster"] = poster;
        this.ifiDefaultConfig["source"] = source
        this.ifiDefaultConfig["duration"] = duration;
        let tracking = {
            "moduleName": "vidodp",
            "toolin": "video - odp"
        };
        let bundleJS = document.createElement('script');
        let videoEl = document.getElementById('dp-video-player');
        document.getElementsByTagName("body")[0].appendChild(bundleJS);
        bundleJS.onload = () => {
            window.myVideoPlayer = new Medscape.modules.StreamingVideoPlayer(videoEl, this.ifiDefaultConfig, tracking);
            window.myVideoPlayer.create().then(event => {
                    // All the event listeners will go here 
                },
                error => {
                    console.error("ERROR:", error);
                });
        };
        bundleJS.src = '//img.' + getDomain() + 'medscapestatic.com/medscape-promo/streaming-video-player/js/streaming-video-player.bundle.js';
    }


}